const modelSlide = (() => {
  const pageModelroom = (document.getElementById("pageModelroom") != null);
  if (pageModelroom) {


    let secModel = document.querySelector('#jsSecModel');
    let modelSlide = secModel.querySelector('.js-swiper');
    let modelThumbnail = secModel.querySelector('.js-thumbnail');


    //スライダー
    const thumbnail = new Swiper(modelThumbnail, {
      slidesPerView: 'auto',
    });


    //スライダー
    const swiper = new Swiper(modelSlide, {
      loop: true,
      speed: 1500,
      effect: 'fade',
      slidesPerView: 1, // 一度に表示する枚数
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      thumbs: {
        swiper: thumbnail,
      },
    });


  }
})();
export default modelSlide;