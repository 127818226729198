const topMain = () => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {



    let secMain = document.querySelector('#jsSecMain');
    let mainWrap = secMain.querySelector('.js-main-wrap');

    let bgs = mainWrap.querySelectorAll('.js-bg');
    let illusts = mainWrap.querySelectorAll('.js-illust');
    let txts = mainWrap.querySelectorAll('.js-txt');
    let wfss = mainWrap.querySelectorAll('.js-wfs');
    let imgs = mainWrap.querySelectorAll('.js-img');



    gsap.set(bgs[1], {
      autoAlpha: 0,
    });
    gsap.set(bgs[2], {
      autoAlpha: 0,
    });

    gsap.set(illusts[0], {
      autoAlpha: 0,
    });
    gsap.set(illusts[1], {
      autoAlpha: 0,
    });

    gsap.set(txts[0], {
      autoAlpha: 0,
    });

    gsap.set(wfss[0], {
      autoAlpha: 0,
    });
    gsap.set(wfss[1], {
      autoAlpha: 0,
    });
    gsap.set(wfss[2], {
      autoAlpha: 0,
    });

    gsap.set(imgs[0], {
      autoAlpha: 0,
    });


    let sW = window.innerWidth;
    let wfsWidth
    let wfsTop
    let wfsLeft
    if (sW > 750) {
      wfsWidth = '42rem'
      wfsTop = '50%'
      wfsLeft = '35%'
    } else {
      wfsWidth = '31rem'
      wfsTop = '55%'
      wfsLeft = '23%'
    }


    gsap.to(txts[0], {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.in",
    }, 1);

    gsap.to(illusts[0], {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.in",
    }, 2);

    gsap.to(txts[0], {
      autoAlpha: 0,
      duration: 1,
      ease: "power1.in",
    }, 4);
    gsap.to(illusts[0], {
      autoAlpha: 0,
      duration: 1,
      ease: "power1.in",
    }, 4);

    gsap.to(wfss[0], {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.in",
    }, 5);

    gsap.to(wfss[0], {
      autoAlpha: 0,
      duration: 1,
      ease: "power1.in",
    }, 7);
    // gsap.to(wfss[1], {
    //   autoAlpha: 1,
    //   duration: 1,
    //   ease: "power1.in",
    // }, 7);
    // gsap.to(bgs[1], {
    //   autoAlpha: 1,
    //   duration: 1,
    //   ease: "power1.in",
    // }, 7);

    gsap.to(wfss[1], {
      autoAlpha: 0,
      duration: 1,
      ease: "power1.in",
    }, 7);
    gsap.to(wfss[2], {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.in",
    }, 7);
    gsap.to(bgs[2], {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.in",
    }, 7);

    gsap.to(illusts[1], {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.in",
    }, 9);
    gsap.to(wfss[2], {
      width: wfsWidth,
      top: wfsTop,
      left: wfsLeft,
      duration: 2,
      ease: "power1.inOut",
    }, 9);

    gsap.to(imgs[0], {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.in",
    }, 11);


    // setTimeout(() => {
    //   main1.forEach(element => {
    //     element.classList.add('act')
    //   });
    // }, 800);

    // setTimeout(() => {
    //   main2.forEach(element => {
    //     element.classList.add('act')
    //   });
    // }, 1600);



  }
}
export default topMain;