const funcNav = (() => {

  let nav = document.getElementById('jsNav');
  let navBtn = document.getElementById('jsNavBtn');

  navBtn.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (navBtn.classList.contains('act') == true) {
      nav.classList.remove('act');
      navBtn.classList.remove('act');
    } else {
      nav.classList.add('act');
      navBtn.classList.add('act');
    }
  });


  const pageMap = (document.getElementById("pageMap") != null);
  if (pageMap) {

    let eleem = nav.querySelectorAll('.js-close-link');

    eleem.forEach(element => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        nav.classList.remove('act');
        navBtn.classList.remove('act');
      });
    });

  }




})();
export default funcNav;